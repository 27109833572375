import { useRouter } from "next/router";
import { CopyrightData } from "../../locales/data";

const Copyright = () => {
  const { locale } = useRouter();
  return (
    <div className="text-xs text-center py-4">
      {CopyrightData[locale]}
    </div>
  );
};

export default Copyright;